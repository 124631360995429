.jumbotron {
	background-image: url(../assets/images/slide-1.jpg);
	background-size: cover;
	background-position: 0% 70%; 
	margin: 0;
	padding: 0;
	height: 380px;
	overflow-y: hidden;
	.catalog {
		a {
			display: block;
			color: @blue;
			text-transform: uppercase;
			font-weight: bold;
			padding: 30px;
			background: rgba(0,0,0,.8);
			img {
				max-width: 100%;
			}
		}
	}
	.hero-content {
		position: relative;
		h2 {
			text-transform: uppercase; 
			color: #fff; 
			margin-top: 300px; 
			background: rgba(0,0,0,.8);
			padding: 5px 20px;
		}
	}
}