.produse {
	.categ-menu {
		border-right: 1px solid @blue-light;
		h2 {
			font-size: 14px;
			color: @blue-text-dark;
			font-weight: bold;
			text-transform: uppercase;
		}
	}
	ul.list-unstyled {
		li.main-li {
			&:hover {
				background: @blue-light;
				border-right: 2px solid @blue;
				-webkit-transition: background-color 500ms linear;
			    -moz-transition: background-color 500ms linear;
			    -o-transition: background-color 500ms linear;
			    -ms-transition: background-color 500ms linear;
			    transition: background-color 500ms linear;
			}
			a {
				color: @blue;
				font-weight: bold;
				font-size: 13px;
				text-transform: uppercase;
				display: block;
				width: 100%;
				padding: 10px;
				&:hover {
					text-decoration: none;
				}
			}
		}
		li.has-submenu {
			ul.submenu {
				li {
					a {
						width: 100%;
						font-weight: normal;
						display: block;
						color: @blue-text-light;
						text-transform: none;
						padding: 0;
						&:hover {
							background: @blue-text-dark;
							color: #fff;
							-webkit-transition: background-color 500ms linear;
						    -moz-transition: background-color 500ms linear;
						    -o-transition: background-color 500ms linear;
						    -ms-transition: background-color 500ms linear;
						    transition: background-color 500ms linear;
						}
					}
				}
			}
		}
	}/* categ menu */
	.products {
		.product {
			padding: 1px;
			background: @blue-light;
			margin: 20px 0px;
		    .wrap-name {
				padding: 0 20px;
				h2 {
					font-size: 13px;
					margin: 0;
					padding: 20px 0px;
					text-transform: uppercase;
					font-weight: bold;
					color: @blue-text-light;
				}
			}
			&:hover {
				background: @blue;
				-webkit-transition: background-color 500ms linear;
			    -moz-transition: background-color 500ms linear;
			    -o-transition: background-color 500ms linear;
			    -ms-transition: background-color 500ms linear;
			    transition: background-color 500ms linear;
				h2 {
					color: #fff;
				}
			}
		}
		
	}
}