.sub-header {
	border-bottom: 1px solid #eee;
	margin-bottom: 30px;
	.col-sm-3 {
		margin: 0;
		padding: 0;
		&:first-child a {
			border-left: 1px solid #e1e1e1;
		}
	}
	.box {
		a {
			border-right: 1px solid #e1e1e1;
			transistion: color .2s linear, box-shadow .2s linear;
			display: block;
			margin: 0;
			padding: 19px 9px;
			img {
				width: 100%;
			}
			&:hover {
				box-shadow: inset 0 0 10px rgba(0,0,0,0.13)
			}
		}
		
	}
}
