.footer {
	background: @blue-light;
	margin-top: 30px;
	h3 {
		color: @blue-text-dark;
	}
	.linkuri-utile {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				a {
					color: @blue-text;
				}
			}
		}
	}
	.btn-send {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	button, button:focus, button:hover {
		background: @blue;
		color: #fff;
		border: @blue-dark;
	}
	.last-col {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				margin-top: 10px;
				i {
					margin-right: 10px;
					float: left;
					margin-top: 3px;
				}
				a {
					display: block;
					float: left;
					max-width: 90%;
					color: @blue-text;
				}
			}
		}
	}
}