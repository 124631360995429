.top-nav {
  background-color: #e8eef9;
}
.top-nav .navbar {
  background: #e8eef9;
  border: none;
  min-height: initial;
}
.top-nav .navbar li:hover {
  background: #376ed1;
}
.top-nav .navbar a {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #8392b0;
  font-size: 12px;
}
.top-nav .navbar a:hover {
  background: #376ed1;
}
.navbar {
  margin-bottom: 0;
}
.middle-space {
  height: 120px;
}
.middle-space .certificari {
  max-height: 90px;
  padding-top: 20px;
}
.navbar-brand {
  margin: 0;
  padding: 0;
  display: block;
  height: 100%;
}
.navbar-brand img {
  max-height: 100%;
  margin-top: 30px;
}
.bottom-menu {
  background: #E8EEF9;
}
.bottom-menu ul.list-unstyled {
  display: block;
  width: 100%;
  height: 60px;
  margin-bottom: 0;
  padding-bottom: 0;
  background-color: #1D2226;
}
.bottom-menu ul.list-unstyled li.first-level {
  float: left;
  display: block;
  position: relative;
}
.bottom-menu ul.list-unstyled li.first-level a {
  padding: 21px;
  display: block;
  text-decoration: none;
  color: #93A7B8;
  max-height: 60px;
}
.bottom-menu ul.list-unstyled li.first-level a:hover {
  text-decoration: none;
  background: #111;
}
.bottom-menu ul.list-unstyled li.has-submenu ul.submenu {
  display: none;
  position: absolute;
  padding: 0;
  width: 120px;
  top: 60px;
  z-index: 99;
  border: 1px solid #e8eef9;
}
.bottom-menu ul.list-unstyled li.has-submenu ul.submenu li {
  display: block;
}
.bottom-menu ul.list-unstyled li.has-submenu ul.submenu li a {
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}
.bottom-menu ul.list-unstyled li.has-submenu ul.submenu li a:hover {
  background-color: #ddd;
}
.bottom-menu ul.list-unstyled li.has-submenu:hover ul.submenu {
  display: block;
}
.jumbotron {
  background-image: url(../assets/images/slide-1.jpg);
  background-size: cover;
  background-position: 0% 70%;
  margin: 0;
  padding: 0;
  height: 380px;
  overflow-y: hidden;
}
.jumbotron .catalog a {
  display: block;
  color: #376ed1;
  text-transform: uppercase;
  font-weight: bold;
  padding: 30px;
  background: rgba(0, 0, 0, 0.8);
}
.jumbotron .catalog a img {
  max-width: 100%;
}
.jumbotron .hero-content {
  position: relative;
}
.jumbotron .hero-content h2 {
  text-transform: uppercase;
  color: #fff;
  margin-top: 300px;
  background: rgba(0, 0, 0, 0.8);
  padding: 5px 20px;
}
.sub-header {
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
}
.sub-header .col-sm-3 {
  margin: 0;
  padding: 0;
}
.sub-header .col-sm-3:first-child a {
  border-left: 1px solid #e1e1e1;
}
.sub-header .box a {
  border-right: 1px solid #e1e1e1;
  transistion: color 0.2s linear, box-shadow 0.2s linear;
  display: block;
  margin: 0;
  padding: 19px 9px;
}
.sub-header .box a img {
  width: 100%;
}
.sub-header .box a:hover {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.13);
}
.despre .headline {
  border-bottom: 1px solid #e1e1e1;
}
.despre .headline h2 {
  display: inline-block;
  border-bottom: 2px solid #376ed1;
  margin-bottom: 0;
}
.despre .content {
  margin: 30px 0px;
}
.despre .content img {
  max-width: 100%;
}
.despre .home-gallery a {
  display: block;
  overflow: hidden;
  height: 150px;
  border-radius: 15px;
}
.despre .home-gallery a img {
  max-width: 100%;
}
.lightbox {
  display: none;
}
.footer {
  background: #e8eef9;
  margin-top: 30px;
}
.footer h3 {
  color: #4d5d7e;
}
.footer .linkuri-utile ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer .linkuri-utile ul li a {
  color: #8392b0;
}
.footer .btn-send {
  margin-top: 15px;
  margin-bottom: 15px;
}
.footer button,
.footer button:focus,
.footer button:hover {
  background: #376ed1;
  color: #fff;
  border: #2152ab;
}
.footer .last-col ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer .last-col ul li {
  margin-top: 10px;
}
.footer .last-col ul li i {
  margin-right: 10px;
  float: left;
  margin-top: 3px;
}
.footer .last-col ul li a {
  display: block;
  float: left;
  max-width: 90%;
  color: #8392b0;
}
.despre-noi .content-despre-noi h3 {
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}
.despre-noi .firme {
  padding: 30px 0;
}
.despre-noi .firme ul {
  padding: 10px 20px;
  list-style: circle;
}
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
.thumbnail {
  height: 200px;
}
.thumbnail img.pull-right {
  width: 50px;
}
html {
  width: 100%;
  max-height: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
/* Do not remove this comments bellow. It's the markers used by gulp-inject to inject
   all your less files automatically */
#contact .box {
  background: #e8eef9;
}
#contact .c-divider {
  border-top: 1px solid #fff;
}
#contact .c-icon-block {
  padding: 10px;
}
.produse {
  /* categ menu */
}
.produse .categ-menu {
  border-right: 1px solid #e8eef9;
}
.produse .categ-menu h2 {
  font-size: 14px;
  color: #4d5d7e;
  font-weight: bold;
  text-transform: uppercase;
}
.produse ul.list-unstyled li.main-li:hover {
  background: #e8eef9;
  border-right: 2px solid #376ed1;
  -webkit-transition: background-color 500ms linear;
  -moz-transition: background-color 500ms linear;
  -o-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}
.produse ul.list-unstyled li.main-li a {
  color: #376ed1;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding: 10px;
}
.produse ul.list-unstyled li.main-li a:hover {
  text-decoration: none;
}
.produse ul.list-unstyled li.has-submenu ul.submenu li a {
  width: 100%;
  font-weight: normal;
  display: block;
  color: #8998b7;
  text-transform: none;
  padding: 0;
}
.produse ul.list-unstyled li.has-submenu ul.submenu li a:hover {
  background: #4d5d7e;
  color: #fff;
  -webkit-transition: background-color 500ms linear;
  -moz-transition: background-color 500ms linear;
  -o-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}
.produse .products .product {
  padding: 1px;
  background: #e8eef9;
  margin: 20px 0px;
}
.produse .products .product .wrap-name {
  padding: 0 20px;
}
.produse .products .product .wrap-name h2 {
  font-size: 13px;
  margin: 0;
  padding: 20px 0px;
  text-transform: uppercase;
  font-weight: bold;
  color: #8998b7;
}
.produse .products .product:hover {
  background: #376ed1;
  -webkit-transition: background-color 500ms linear;
  -moz-transition: background-color 500ms linear;
  -o-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}
.produse .products .product:hover h2 {
  color: #fff;
}
a.btn-success,
a.btn-success:focus,
a.btn-success:focus {
  background-color: #376ed1;
  border-color: #2152ab;
}
a.btn-success:hover,
a.btn-success:focus:hover,
a.btn-success:focus:hover {
  background: #2152ab;
}
button.btn-success,
button.btn-success:focus,
button.btn-success:focus {
  background-color: #376ed1;
  border-color: #2152ab;
}
button.btn-success:hover,
button.btn-success:focus:hover,
button.btn-success:focus:hover {
  background: #2152ab;
}
img {
  max-width: 100%;
}
input,
textarea,
select {
  border: 1px solid #376ed1 !important;
}
.page-header {
  background: #fff;
  border-bottom: 1px solid #e8eef9;
  color: #fff;
  margin: 0px 0px 30px 0px;
  padding-bottom: 0;
}
.page-header h1 {
  margin: 0;
  padding: 20px 10px;
  color: #4d5d7e;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.form-field {
  margin-bottom: 20px;
}
.form-field label {
  width: 100%;
}
.form-field input {
  width: 100%;
  height: 35px;
  padding: 5px 10px;
}
.form-field textarea {
  width: 100%;
  height: 280px;
}
label {
  color: #4d5d7e;
}
