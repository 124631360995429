.top-nav {
	background-color: @blue-light;
	.navbar {
		background: @blue-light;
		border: none;
		min-height: initial;
		li {
			&:hover {
				background: @blue;
			}
		}
		a {
			padding-top: 5px;
			padding-bottom: 5px;
			color: @blue-text;
			font-size: 12px;
			&:hover {
				background: @blue;
			}
		}
	}
}
.navbar {
	margin-bottom: 0;
}
.middle-space {
	height: 120px;
	.certificari {
		max-height: 90px;
		padding-top: 20px;
	}	
}
.navbar-brand {
	margin: 0;
	padding: 0;
	display: block;
	height: 100%;
	img {
		max-height: 100%;
		margin-top: 30px;
	}
}
.bottom-menu {
	background: #E8EEF9;
	ul.list-unstyled {
		display: block;
		width: 100%;
		height: 60px;
		margin-bottom: 0;
		padding-bottom: 0;
		background-color: #1D2226;
		li.first-level {
			float: left;
			display: block;
			position: relative;
			a {
				padding: 21px;
				display: block;
				text-decoration: none;
				color: #93A7B8;
				max-height: 60px;
				&:hover {
					text-decoration: none;
					background: #111;
				}
			}
		}
		li.has-submenu {
			ul.submenu {
				display: none;
				position: absolute;
				padding: 0;
				width: 120px;
				top: 60px;
				z-index: 99;
				border: 1px solid @blue-light;
				li {
					display: block;
					a {
						padding: 10px;
						background-color: #fff;
						border-bottom: 1px solid #eee;
						&:hover {
							background-color: #ddd;
						}
					}
				}
			}
			&:hover {
				ul.submenu {
					display: block;
				}
			}
		}
	}
}