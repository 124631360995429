#contact {
	.box {
		background: @blue-light;
	}
	.c-divider {
		border-top: 1px solid #fff;
	}
	.c-icon-block {
		padding: 10px;
	}
}