.despre {
	.headline {
		border-bottom: 1px solid #e1e1e1;
		h2 {
			display: inline-block;
			border-bottom: 2px solid @blue;
			margin-bottom: 0;
		}
	}
	.content {
		margin: 30px 0px;
		img {
			max-width: 100%;
		}
	}
	.home-gallery {
		a {
			display: block;
			overflow: hidden;
			height: 150px;
			border-radius: 15px;
			img {
				max-width: 100%;
			}
		}
	}
}
.lightbox {
	display: none;
}