.despre-noi {
	.content-despre-noi {
		h3 {
			margin: 0;
			padding: 0;
			margin-bottom: 30px;
		}
	}
	.firme {
		padding: 30px 0;
		ul {
			padding: 10px 20px;
			list-style: circle;
		}
	}
}