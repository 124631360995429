// variables
@blue-light: rgb(232, 238, 249);
@blue: rgb(55, 110, 209);
@blue-dark: #2152AB;
@blue-text: rgb(131, 146, 176);
@blue-text-dark: #4d5d7e;
@blue-text-light: #8998b7;


// components imports
@import "components/navbar/navbar.less";
@import "components/hero-area/jumbtron.less";
@import "components/sub-header/sub-header.less";
@import "components/despre/despre.less";
@import "components/footer/footer.less";

// page imports
@import "pages/despre/despre-noi.less";
@import "pages/productie-distributie/productie-distributie.less";
@import "pages/noutati/noutati.less";

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.thumbnail {
  height: 200px;

  img.pull-right {
    width: 50px;
  }
}
html {
	width: 100%;
	max-height: 100%;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
}
body {
	font-family: 'Open Sans', Arial, Helvetica, sans-serif;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
}


/* Do not remove this comments bellow. It's the markers used by gulp-inject to inject
   all your less files automatically */
// injector
@import 'components/footer/footer.less';
@import 'components/despre/despre.less';
@import 'components/hero-area/jumbtron.less';
@import 'components/navbar/navbar.less';
@import 'components/sub-header/sub-header.less';
@import 'pages/cariere/cariere.less';
@import 'pages/contact/contact.less';
@import 'pages/despre/despre-noi.less';
@import 'pages/noutati/noutati.less';
@import 'pages/produse/produse.less';
@import 'pages/productie-distributie/productie-distributie.less';
// endinjector


a.btn-success, a.btn-success:focus, a.btn-success:focus {
	background-color: @blue;
	border-color: @blue-dark;
	&:hover {
		background: @blue-dark;
	}
}
button.btn-success, button.btn-success:focus, button.btn-success:focus {
	background-color: @blue;
	border-color: @blue-dark;
	&:hover {
		background: @blue-dark;
	}
}
img {
	max-width: 100%;
}
input, textarea, select {
	border: 1px solid @blue!important;
}
.page-header {
	background: #fff;
	border-bottom: 1px solid @blue-light;
	color: #fff;
	margin: 0px 0px 30px 0px;
	padding-bottom: 0;
	h1 {
		margin: 0;
		padding: 20px 10px;
		color: @blue-text-dark;
		font-size: 18px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

// form pentru cariere dar poate fi folosit in toata aplicatia
.form-field {
  margin-bottom: 20px;
}
.form-field label {
   width: 100%;
}
.form-field input {
   width: 100%;
  height: 35px;
  padding: 5px 10px;
}
.form-field textarea {
   width: 100%;
  height: 280px;
}
label {
	color: @blue-text-dark;
}